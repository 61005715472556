import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getFavoriteProducts,
  deleteFavoriteProducts,
  addFavoriteProducts,
} from "../services";
import { getUserId } from "../utils/jwt";
import { useSetRecoilState } from "recoil";
import { favoriteProductState } from "../atoms/favoriteProductsAtoms";

export const useGetFavoriteProducts = (page = 1, perPage = 10) => {
  const setFavoriteProducts = useSetRecoilState(favoriteProductState);
  const userId = getUserId();
  return useQuery(
    ["favoriteProducts", userId, page, perPage],
    () => getFavoriteProducts(page, perPage),
    {
      retry: 0,
      enabled: !!userId,
      onSuccess: (data) => {
        setFavoriteProducts(data);
      },
    }
  );
};

export const useDeleteFavoriteProducts = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteFavoriteProducts, {
    onSuccess: () => {
      queryClient.invalidateQueries(["favoriteProducts"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["favoriteProducts"]);
    },
  });
};

export const useAddFavoriteProducts = () => {
  const queryClient = useQueryClient();

  return useMutation(addFavoriteProducts, {
    onSuccess: () => {
      queryClient.invalidateQueries(["favoriteProducts"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["favoriteProducts"]);
    },
  });
};
