import { memo } from "react";
import Image from "../shared/Image";
import quickLook from "../../styles/ProductQuickLook.module.css";
import { ASSETS_BASE_URL } from "../../constants";
import PlayButton from "../PlayButton";
import FavIcon from "../products/FavIcon";

const ProductSlide = memo(
  ({
    sku,
    isVideoSlide = false,
    skuType = "",
    handleOpen = () => null,
    alt = sku,
    imageUrl = ''
  }) => {
    if (!sku) return null;
    const label = sku + (skuType ? skuType : "");
    return (
      <div className={quickLook["quick-view-wrapper"]} onClick={handleOpen}>
        <div className={quickLook["quick-view-label"]}>{label}</div>
        {!isVideoSlide && (
          <div className={quickLook["fabBox"]}>
            <FavIcon sku={sku} />
          </div>
        )}
        <Image
          className={quickLook["quick-view-img"]}
          src={
            isVideoSlide
              ? `${ASSETS_BASE_URL}/image/product/video-thumbnail/${sku}-P.jpg`
              : imageUrl ? imageUrl : `${ASSETS_BASE_URL}/image/product/standard/${sku}.png`
          }
          alt={alt}
        />
        <PlayButton isVideoView={isVideoSlide} />
      </div>
    );
  }
);

export default ProductSlide;
