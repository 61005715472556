import { ADMIN_WITH_PLACE_ORDER_PERMISSION } from "../constants";
import { logOutUser } from "../services";

export const getRegenBasicHeader = (headers) => {
  return {
    ...headers,
    Authorization:
      // eslint-disable-next-line
      "Basic " + btoa(process.env.REACT_APP_REGEN_BASIC + ":"),
  };
};

export const getRegenJwtHeader = (headers) => {
  const jwt = getRegenToken();
  if (jwt) {
    return { ...headers, Authorization: `Bearer ${jwt}` };
  }
  return headers;
};

export const getRegenAdminJwtHeader = (headers) => {
  const jwtAdmin = getRegenAdminToken();
  if (jwtAdmin) {
    return { ...headers, Authorization: `Bearer ${jwtAdmin}` };
  }
  return headers;
};

export const setRegenToken = (jwt) => {
  localStorage.setItem("jwt", jwt);
};

export const getRegenToken = () => {
  return localStorage.getItem("jwt");
};

export const setUserId = (id) => {
  localStorage.setItem("userId", id);
};

export const getUserId = () => {
  return localStorage.getItem("userId");
};

export const getIntercomUser = () => {
  const user = getUser();
  if (user) {
    return {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      userId: user.accountId,
      phone: user.phone,
      customAttributes: {
        Account: user.accountType,
        "Rewards Tier": user.rewardsTier ? user.rewardsTier : "",
        Subscribed: user.smsOptIn,
      },
    };
  }
  return;
};

export const setHasImpersonate = (isImpersonate = false) => {
  return localStorage.setItem("hasImpersonate", isImpersonate.toString());
};

export const getHasImpersonate = () => {
  return localStorage.getItem("hasImpersonate") === "true";
};

export const setUser = (user) => {
  if (user) {
    return localStorage.setItem("user", JSON.stringify(user));
  }
};

export const setPermissions = (permissions) => {
  if (permissions && permissions.length > 0) {
    return localStorage.setItem("permissions", JSON.stringify(permissions));
  }
};

export const getPermissions = () => {
  const permissions = localStorage.getItem("permissions");
  if (permissions && permissions !== "undefined") {
    return JSON.parse(permissions);
  }
  return [];
};

export const checkPermission = (permission) => {
  const permissions = getPermissions();
  return permissions.includes(permission);
}



export const getUser = () => {
  const user = localStorage.getItem("user");
  if (user && user !== "undefined") {
    return JSON.parse(user);
  }
  return null;
};

export const isSelfSignedUpUser = () => {
  const user = getUser();
  return user?.selfSignUp === true;
};

export const setAdminUserId = (id) => {
  localStorage.setItem("adminUserId", id);
};

export const getAdminUserId = () => {
  return localStorage.getItem("adminUserId");
};

export const setRegenAdminToken = (jwtAdmin) => {
  localStorage.setItem("jwtAdmin", jwtAdmin);
};

export const getRegenAdminToken = () => {
  return localStorage.getItem("jwtAdmin");
};

export const setSalesChannel = (site) => {
  localStorage.setItem("salesChannel", site);
};

export const getSalesChannel = () => {
  return localStorage.getItem("salesChannel");
};

export const getRole = () => {
  const salesChannel = checkLogin() ? getSalesChannel() : "";
  let currentRole = "";
  switch (salesChannel) {
    case "b2b":
      currentRole = "Pro";
      break;
    case "school":
      currentRole = "School";
      break;
    case "retail":
      currentRole = "Retail";
      break;
    default:
      currentRole = "Web";
  }
  return currentRole;
};

export const clearLocalStorage = () => {
  //log out user on server
  const userId = getUserId();
  if (userId) logOutUser(userId);

  //log out user on client
  const deviceId = getDeviceId();
  localStorage.clear();
  setDeviceId(deviceId);
};

export const getChannelLink = () => {
  const salesChannel = getSalesChannel();
  const jwt = getRegenToken();
  if (salesChannel && jwt) {
    return salesChannel === "b2b"
      ? "/b2b"
      : salesChannel === "retail"
        ? "/retail"
        : salesChannel === "school"
          ? "/school"
          : null;
  }
  clearLocalStorage();
  return null;
};

export const isProLoggedIn = () => {
  const salesChannel = getSalesChannel();
  const jwt = getRegenToken();
  if (jwt && salesChannel === "b2b") {
    return true;
  }
  return false;
};

export const isRetailLoggedIn = () => {
  const salesChannel = getSalesChannel();
  const jwt = getRegenToken();
  if (jwt && salesChannel === "retail") {
    return true;
  }
  return false;
};

export const isSchoolLoggedIn = () => {
  const salesChannel = getSalesChannel();
  const jwt = getRegenToken();
  if (jwt && salesChannel === "school") {
    return true;
  }
  return false;
};


export const checkLogin = () => {
  return getRegenToken() ? true : false;
};

export const checkAdminLogin = () => {
  return getRegenAdminToken() ? true : false;
};

export const setStore = (user) => {
  return localStorage.setItem("store", JSON.stringify(user));
};

export const getStore = () => {
  return JSON.parse(localStorage.getItem("store"));
};

export const parseJwt = () => {
  const token = getRegenToken();
  if (token && token !== "undefined") {
    const base64Url = token.split(".")?.[1];
    const base64 = base64Url?.replace("-", "+")?.replace("_", "/");
    return base64 ? JSON.parse(window.atob(base64)) : null;
  }
  return null;
};

export const setDeviceId = (deviceId) => {
  localStorage.setItem("deviceId", deviceId);
};

export const getDeviceId = () => {
  return localStorage.getItem("deviceId");
};

export const isSuperAdmin = () => {
  const { admUserEmail } = parseJwt() || {};
  return ADMIN_WITH_PLACE_ORDER_PERMISSION.includes(admUserEmail);
};

export const checkRewardEligibility = () => {
  const user = getUser();
  if (
    user?.discountModel !== "Professional" ||
    (user?.discountModel === "Professional" && user?.tier === "Tier 4") || user?.exemptForReward
  ) {
    return false;
  }
  return true;
};

export const hideRewardPage = () => {
  const user = getUser();
  if (
    user?.discountModel !== "Professional" ||
    (user?.discountModel === "Professional" && user?.tier === "Tier 4")
  ) {
    return false;
  }
  return true;
};
