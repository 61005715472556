import { useMemo } from "react";
import Icon from "@mui/material/Icon";
import Done from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";
import { ASSETS_BASE_URL } from "../../constants";
import css from "./StepIcon.module.css";

export const CustomStepIcon = styled("div")(({ completed, active }) => ({
  width: 16,
  height: 16,
  borderRadius: "50%",
  border: active ? "2px solid" : "1px solid",
  borderColor: "var(--purple-500)",
  backgroundColor: completed ? "var(--purple-500)" : "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CustomStepShadow = styled("div")(({ active }) => ({
  width: 22,
  height: 22,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: active ? "0px 0px 0px 2.5px #91239163" : "",
}));

function StepIcon(props) {
  const {
    step,
    active,
    sequence,
    stepIconClick = () => {},
    showGift = true,
  } = props;

  const anime = useMemo(() => {
    if (step.completed) {
      return <Done sx={{ fill: "var(--white)", fontSize: "14px" }} />;
    }
    if (step.couponMessage && showGift) {
      return (
        <Icon className={css["stepIcon"]} fontSize="1.3em">
          <img
            src={`${ASSETS_BASE_URL}/image/onboarding-img/gift.svg`}
            alt={step.title}
            style={{ width: "12px", height: "12px" }}
          />
        </Icon>
      );
    }
    if (sequence === active) {
      return (
        <div
          style={{
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: "var(--purple-500)",
          }}
        />
      );
    }
  }, [
    active,
    sequence,
    showGift,
    step.completed,
    step.couponMessage,
    step.title,
  ]);

  return (
    <div onClick={() => stepIconClick(step, sequence)}>
      <CustomStepShadow active={active === sequence}>
        <CustomStepIcon completed={step.completed} active={active === sequence}>
          {anime}
        </CustomStepIcon>
      </CustomStepShadow>
    </div>
  );
}

export default StepIcon;
