import { useMemo } from "react";
import { getRewardInfo } from "../../utils/rewards";
import { Box } from "@mui/material";
import { checkRewardEligibility } from "../../utils/jwt";
import { useRegenRewards } from "../../hooks/useRegenRewards";
import { numberWithCommas } from "../../utils";
import { roundTo } from "../../utils/roundTo";
import flipIcon from "../../assets/img/flipIcon.gif";
import shippingIcon from "../../assets/img/shippingIcon.svg";
import css from "../../styles/RewardsIncentive.module.css";
import { StyledLabelCaps } from "../../styled";

const RewardsIncentive = ({ subTotal, hasFreeShipping = false }) => {
  const { data, isLoading, isError } = useRegenRewards();
  const eligibleForRewards = checkRewardEligibility();

  const rewards = useMemo(() => {
    return eligibleForRewards && data ? getRewardInfo(data) : null;
  }, [data, eligibleForRewards]);

  if (isError || isLoading || !eligibleForRewards) return null;

  const { nextTierSpend, isLastTier, isFirstTier } = rewards || {};

  if (rewards && !isLastTier) {
    // Don't show anything if user has reached next tier threshold
    if (subTotal >= nextTierSpend) return null;

    const amountForFreeShipping = 250 - subTotal;
    const amountForNextTier = nextTierSpend - subTotal;

    // Only show shipping info if:
    // 1. User does not have free shipping
    // 2. User is in PRO tier
    // 3. Subtotal is less than the amount required for free shipping

    if (isFirstTier && !hasFreeShipping && amountForFreeShipping > 0) {
      const showShippingInfo = amountForFreeShipping < amountForNextTier;

      if (showShippingInfo) {
        return (
          <Box className={css.flexBox}>
            <img
              className={css.shippingIcon}
              src={shippingIcon}
              alt="shipping icon"
            />
            <StyledLabelCaps sx={{ p: 0 }}>
              Add ${numberWithCommas(roundTo(amountForFreeShipping, 2).toFixed(2))} to
              get free shipping
            </StyledLabelCaps>
          </Box>
        );
      }
    }

    // Show progress to next tier with dynamically calculated amount
    return (
      <Box className={css.flexBox}>
        <img className={css.flipIcon} src={flipIcon} alt="flip icon" />
        <Box className={css.infoTextContainer}>
          <StyledLabelCaps className={css.relativeInfoText} sx={{ p: 0 }}>
            Add ${numberWithCommas(roundTo(amountForNextTier, 2).toFixed(2))}
            <span className={css.diamond1}>&#10022;</span>
          </StyledLabelCaps>
          <StyledLabelCaps className={css.infoText} sx={{ p: 0, ml: 0.5 }}>
            to reach {rewards?.nextTierLabel}
          </StyledLabelCaps>
          <StyledLabelCaps className={css.diamond2}>&#10022;</StyledLabelCaps>
        </Box>
      </Box>
    );
  }
  return null;
};

export default RewardsIncentive;
