import { useQuery } from "react-query";
import { getUserId } from "../utils/jwt";
import { getRegenRewards } from "../services";

export const useRegenRewards = (selectedYear) => {
  const userId = getUserId();
  return useQuery(
    ["regenRewards", userId, selectedYear],
    () => getRegenRewards(selectedYear),
    {
      retry: 0,
      enabled: !!userId,
    }
  );
};
