import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CART } from "./Constants";
import RewardsOnOrder from "./RewardsOnOrder";
import FreeShippingBanner from "../FreeShippingBanner";
import CouponHint from "../checkout/CouponHint";
import { isProLoggedIn } from "../../utils/jwt";

const CartHeaedr = ({ data, rewardsData, handleBackdropClick }) => {
  const isPro = isProLoggedIn();
  return (
    <>
      <Box sx={styles.cartHeader}>
        <IconButton
          onClick={handleBackdropClick}
          sx={{ height: "auto", cursor: "pointer" }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
        <h1 style={{ paddingBottom: 0, flexGrow: 1 }} align="center">
          {CART}
        </h1>
      </Box>
      {isPro ? (
        <RewardsOnOrder rewardsData={rewardsData} />
      ) : data?.couponEligibilityDetails ? (
        <CouponHint couponHintDetails={data.couponEligibilityDetails} />
      ) : (
        <FreeShippingBanner />
      )}
    </>
  );
};

const styles = {
  cartHeader: {
    display: "flex",
    alignItems: "center",
    mt: 1.5,
    p: 1.5,
    mb: 1.5,
  },
};

export default CartHeaedr;
