import ProductSlide from "./ProductSlide";
import ProductVideo from "./ProductVideo";
import { Navigation, Pagination } from "swiper";
import { memo, useEffect, useState } from "react";
import { ASSETS_BASE_URL } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Fragment } from "react";

const ProductSwiper = memo(({ skuSet, skuSetWithName, rootSku, hideVideo, packagingImages = [] }) => {
  const [swiper, setSwiper] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (skuSet) {
      swiper?.slideTo(0);
    }
  }, [skuSet, swiper]);

  const getAltTag = (sku) => {
    const item = skuSetWithName?.find((item) => item.sku === sku)
    return item?.shortName || sku
  }

  return (
    <>
      <Swiper
        onSwiper={setSwiper}
        spaceBetween={10}
        navigation={skuSet?.length > 1 || !hideVideo}
        pagination={{
          clickable: true,
        }}
        modules={
          skuSet?.length > 0 && skuSet[0] !== ""
            ? skuSet?.length < 2 && hideVideo
              ? []
              : [Pagination, Navigation]
            : [Pagination, Navigation]
        }
        style={{ maxWidth: "100%", paddingRight: "2px", paddingBottom: 40 }}
      >
        {skuSet?.map((sku) => {
          const packagingImage = packagingImages?.find(img => img.sku === sku);
          return (
            <Fragment key={`product-${sku}`}>
              <SwiperSlide key={`slide-${sku}`}>
                <ProductSlide sku={sku} alt={getAltTag(sku)} />
              </SwiperSlide>
              {packagingImage?.standard && packagingImage?.standard?.length > 0 &&
                packagingImage?.standard?.map((imageUrl, index) => (
                  imageUrl && (
                    <SwiperSlide key={`packaging-${sku}-${index}`}>
                      <ProductSlide
                        sku={sku}
                        alt={`${getAltTag(sku)} Packaging ${index + 1}`}
                        imageUrl={imageUrl}
                      />
                    </SwiperSlide>
                  )
                ))}
            </Fragment>
          );
        })}

        {!hideVideo && (
          <SwiperSlide key={`video-${rootSku}`}>
            <ProductSlide
              sku={rootSku}
              isVideoSlide={true}
              handleOpen={handleOpen}
              alt={getAltTag(rootSku)}
            />
          </SwiperSlide>
        )}
      </Swiper>
      <ProductVideo
        poster={`${ASSETS_BASE_URL}/image/product/video-thumbnail/${rootSku}-P.png`}
        src={`${ASSETS_BASE_URL}/image/product/retail/${rootSku}-V.mp4`}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
});
export default ProductSwiper;
