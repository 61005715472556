import { memo, useState, useCallback, useEffect, useMemo } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Spacer from "../Spacer";
import quickLook from "../../styles/ProductQuickLook.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { checkLogin } from "../../utils/jwt";
import className from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import SkuInfo from "./SkuInfo";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ProductQuickLookInventoryInfo from "./ProductQuickLookInventoryInfo";
import ProductSwiper from "./ProductSwiper";
import NotifyMe from "../products/NotifyMe";
import SimilarProducts from "../products/SimilarProducts";
import { useGetFavoriteProducts } from "../../hooks/useFavorites";

const ProductQuickLook = ({
  sku,
  skuSet,
  name,
  ourLine,
  shortDesc,
  category,
  disabled,
  handleClose,
  relatedSku = [],
  rootSku,
  hideVideo = false,
  packagingImages = [],
}) => {
  useGetFavoriteProducts();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedSku, setSelectedSku] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = checkLogin();
  const handleSkuSelection = (sku) => setSelectedSku(sku);

  const handleInitSku = useCallback(() => {
    if (sku) setSelectedSku(sku);
  }, [sku]);

  const handleLogin = () => {
    navigate("/login", {
      state: {
        hasQuickLook: location,
      },
    });
  };

  useEffect(() => {
    handleInitSku();
  }, [handleInitSku]);

  const currentSkus = useMemo(() => {
    if (selectedSku === rootSku) {
      return [rootSku, ...relatedSku];
    } else {
      return [selectedSku];
    }
  }, [selectedSku, rootSku, relatedSku]);

  const handleViewMore = () => navigate(`/products/${sku}`);

  return (
    <div className={quickLook["quick-view-modal"]}>
      <div className={quickLook["quick-view-content"]}>
        <Container
          className={className(quickLook["quick-view-paper"], "fade-in")}
        >
          <NotifyMe />
          <div className={quickLook["quick-view-close"]}>
            <Button variant="btn-icon-primary" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </div>
          {matches && (
            <>
              <Spacer height={10} />
              <ProductDetailsHeader data={{ name, disabled }} />
              <Spacer height={20} />
            </>
          )}
          <Grid container spacing={matches ? 0 : 5}>
            <Grid item xs={12} md={5}>
              <ProductSwiper
                skuSet={currentSkus}
                rootSku={rootSku}
                hideVideo={hideVideo}
                packagingImages={packagingImages}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              {!matches && <ProductDetailsHeader data={{ name, disabled }} />}
              <SkuInfo
                skuSet={skuSet}
                sku={sku}
                selectedSku={selectedSku}
                handleSkuSelection={handleSkuSelection}
              />
              <SimilarProducts skuSet={skuSet} selectedSku={selectedSku} />
              {ourLine && (
                <Typography mb={2.5} pb={0} lineHeight={1}>
                  {ourLine.toUpperCase()}
                </Typography>
              )}
              {category && (
                <Typography mb={2.5} pb={0} lineHeight={1}>
                  {category.toUpperCase()}
                </Typography>
              )}
              <Typography variant="body1" mb={2.5} pb={0}>
                {shortDesc}
              </Typography>
              <ProductQuickLookInventoryInfo
                handleLogin={handleLogin}
                isLoggedIn={isLoggedIn}
                handleViewMore={handleViewMore}
              />
            </Grid>
          </Grid>
          <Spacer height={28}></Spacer>
        </Container>
      </div>
    </div>
  );
};

export default memo(ProductQuickLook);
