import { useQuery } from "react-query";
import { getPackagingImages } from '../services';

const useGetPackagingImages = (skus) => {
  const validSkus = Array.isArray(skus) ? skus.filter(Boolean) : [];

  return useQuery(
    ["packagingImages", validSkus],
    () => getPackagingImages(validSkus),
    {
      retry: 0,
      enabled: validSkus.length > 0,
    }
  );
};

export default useGetPackagingImages;