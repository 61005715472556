import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getUser } from "./jwt";

// Helper function to initialize Sentry
const initializeSentry = (env) => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: env,
    integrations: [new BrowserTracing()],
    beforeSend: (event) => {
      const user = getUser();
      if (user?.accountId) {
        event.user = { id: user.accountId, username: user.accountName };
      }
      return event;
    },
    tracesSampleRate: 0.05,
  });
};

// Initialize Sentry based on environment
const APP_MODE = process.env.REACT_APP_APP_MODE;
const API_URL = process.env.REACT_APP_API_URL;
const defaultEnv = "qa"; // Can be "qa", "uat", or "staging4"

if (APP_MODE === "production") {
  initializeSentry("production");
} else if (APP_MODE === "stage" && API_URL?.includes(defaultEnv)) {
  initializeSentry("stage");
}

// Uncomment to enable Sentry for development mode debugging
// if (APP_MODE === "dev") {
//   initializeSentry("dev");
// }
