import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getCart,
  updateCart,
  deleteFromCart,
  setFreeItem,
  deleteFreeItem,
} from "../services";
import { getCartBySku } from "../services/cart";
import { useSetRecoilState } from "recoil";
import { CartDataState } from "../atoms/cartAtoms";

export const useGetCart = (showTaxes = false, returnToCart = false) => {
  return useQuery(
    ["cart", returnToCart],
    () => getCart(showTaxes, returnToCart),
    {
      initialData: [],
    }
  );
};

export const useGetCartBySku = (sku) => {
  return useQuery(["cartBySku", sku], () => getCartBySku(sku), {
    enabled: sku ? true : false,
  });
};

export const useUpdateCart = () => {
  const queryClient = useQueryClient();
  const setCartData = useSetRecoilState(CartDataState);

  return useMutation(updateCart, {
    onSuccess: (response) => {
      setCartData(response);
      queryClient.invalidateQueries(["cartBySku"]);
      queryClient.invalidateQueries(["rewards"]);
      queryClient.invalidateQueries(["shippingOption"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["cart"]);
      queryClient.invalidateQueries(["cartBySku"]);
    },
  });
};

export const useUpdateCartForCoupon = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCart, {
    onError: () => {
      queryClient.invalidateQueries(["cart"]);
      queryClient.invalidateQueries(["cartBySku"]);
    },
  });
};

export const useDeleteFromCart = () => {
  const queryClient = useQueryClient();
  const setCartData = useSetRecoilState(CartDataState);

  const mutation = useMutation(deleteFromCart, {
    onSuccess: (response) => {
      setCartData(response);
      queryClient.invalidateQueries(["cartBySku"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["cart"]);
      queryClient.invalidateQueries(["cartBySku"]);
    },
  });

  return mutation;
};

export const useSetFreeItem = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((data) => setFreeItem(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["cart"]);
      queryClient.invalidateQueries(["cartBySku"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["cart"]);
      queryClient.invalidateQueries(["cartBySku"]);
    },
  });

  return mutation;
};

export const useDeleteFreeItem = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteFreeItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(["cart"]);
      queryClient.invalidateQueries(["cartBySku"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["cart"]);
      queryClient.invalidateQueries(["cartBySku"]);
    },
  });

  return mutation;
};
