import { hideRewardPage } from "../utils/jwt";
export const ALL = "All";
export const CLEANSERS = "Cleansers";
export const EYE_CREAMS = "Eye Creams";
export const MASQUES = "Masques";
export const EXFOLIATORS = "Exfoliators";
export const LIP_CARE = "Lip Care";
export const MOISTURIZERS = "Moisturizers";
export const SHOP_NOW = "Shop Now";
export const SUNCARE = "Sun Care";
export const SERUM = "Serum";
export const PEELS = "Peel";

const retailProfileMenu = [
  {
    id: "my-account",
    title: "My Account",
    link: "/retail/my-account",
    menuItems: [
      { title: "Profile", link: "/retail/my-account/profile" },
      { title: "My Orders", link: "/retail/my-account/my-orders" },
      { title: "Favorites", link: "/retail/my-account/favorites" },
    ],
  },
];
const proProfileMenu = [
  {
    id: "my-account",
    title: "My Account",
    link: "/b2b/my-account",
    menuItems: [
      { id: "profile", title: "Profile", link: "/b2b/my-account/profile" },
      {
        id: "regen-rewards",
        title: "REGEN Rewards",
        link: "/b2b/my-account/regen-rewards",
      },
      {
        id: "my-orders",
        title: "My Orders",
        link: "/b2b/my-account/my-orders",
      },
      {
        id: "favorites",
        title: "Favorites",
        link: "/b2b/my-account/favorites",
      },
      {
        id: "locations",
        title: "Locations",
        link: "/b2b/my-account/locations",
      },
    ],
  },
  {
    id: "my-store",
    title: "My Store",
    link: "/b2b/my-store",
    menuItems: [
      { id: "info", title: "Info", link: "/b2b/my-store/info" },
      { id: "clients", title: "Clients", link: "/b2b/my-store/clients" },
      {
        id: "retail-orders",
        title: "Retail Orders",
        link: "/b2b/my-store/retail-orders",
      },
      {
        id: "top-products",
        title: "Top Products",
        link: "/b2b/my-store/top-products",
      },
      {
        id: "my-recommendations",
        title: "My Recommendations",
        link: "/b2b/my-store/my-recommendations",
      },
    ],
  },
];
const schoolProfileMenu = [
  {
    id: "my-account",
    title: "My Account",
    link: "/school/my-account",
    menuItems: [
      { id: "profile", title: "Profile", link: "/school/my-account" },
      {
        id: "my-orders",
        title: "My Orders",
        link: "/school/my-account/my-orders",
      },
      {
        id: "favorites",
        title: "Favorites",
        link: "/school/my-account/favorites",
      },
    ],
  },
  {
    id: "education",
    title: "Education",
    link: "/school/education",
  },
];

const navMenu = [
  {
    id: "products",
    title: "Products",
    // link: "/products",
    menuItems: [
      {
        id: "all",
        title: ALL,
        link: "/products",
      },
      {
        id: "cleansers",
        title: CLEANSERS,
        link: "/products?category=Cleanser",
      },
      {
        id: "exfoliators",
        title: EXFOLIATORS,
        link: "/products?category=Exfoliator",
      },
      {
        id: "masques",
        title: MASQUES,
        link: "/products?category=Masque",
      },
      {
        id: "serum",
        title: SERUM,
        link: "/products?category=Serum",
      },
      {
        id: "eye-creams",
        title: EYE_CREAMS,
        link: "/products?category=Eye Cream",
      },
      {
        id: "lip-care",
        title: LIP_CARE,
        link: "/products?category=Lip Care",
      },
      {
        id: "moisturizers",
        title: MOISTURIZERS,
        link: "/products?category=Moisturizer",
      },

      {
        id: "suncare",
        title: SUNCARE,
        link: "/products?category=Sun Care",
      },

      {
        id: "peel",
        title: PEELS,
        link: "/products?category=Peel",
      },
    ],
    isExtendedMenu: true,
    access: ["web", "b2b", "school"],
  },
  {
    //Retailer
    id: "products",
    title: "Products",
    menuItems: [
      {
        id: "all",
        title: ALL,
        link: "/products",
      },
      {
        id: "photoDamage",
        title: "Photo Damage",
        link: "/products?concerns=Photo-Damage",
      },
      {
        id: "aging",
        title: "Aging",
        link: "/products?concerns=Aging",
      },
      {
        id: "dryness",
        title: "Dryness",
        link: "/products?concerns=Dryness",
      },
      {
        id: "post-treatment",
        title: "Post Treatment",
        link: "/products?concerns=Post-Treatment",
      },
      {
        id: "pigmentations",
        title: "Pigmentation",
        link: "/products?concerns=Pigmentation",
      },
      {
        id: "rosacea",
        title: "Rosacea",
        link: "/products?concerns=Rosacea",
      },
      {
        id: "pores",
        title: "Pores",
        link: "/products?concerns=Pores",
      },

      {
        id: "acne",
        title: "Acne",
        link: "/products?concerns=Acne",
      },
    ],
    isExtendedMenu: true,
    access: ["retail"],
  },
  {
    id: "about",
    title: "About",
    menuItems: [
      {
        id: "Charity",
        title: "Charity",
        link: "/charity",
      },
      { id: "our-founder", title: "Our Founder", link: "/founder" },
      {
        id: "unity-advisory-committee",
        title: "Unity Advisory Committee",
        link: "/uac",
      },
    ],
    isExtendedMenu: true,
    access: ["web", "b2b", "retail", "school"],
  },
  {
    id: "becomepro",
    title: "Become a Glymed Pro",
    link: "/become-pro",
    isExtendedMenu: false,
    access: ["web", "retail", "school"],
  },
  {
    id: "locations",
    title: "Find a Glymed Pro",
    link: "/locations",
    isExtendedMenu: false,
    access: ["web"],
  },
  {
    id: "more",
    title: "More",
    access: ["web", "b2b", "retail", "school"],
    menuItems: [
      {
        id: "webinars",
        title: "Webinars",
        link: "/webinars",
        access: ["web", "b2b", "retail", "school"],
      },
      {
        id: "protocols",
        title: "Protocols",
        link: "/protocols",
        access: ["web", "b2b", "retail", "school"],
      },
      {
        id: "education",
        title: "Education",
        link: "/education",
        access: ["web", "b2b", "retail", "school"],
      },
      {
        id: "calendar",
        title: "Calendar",
        link: "/calendar",
        access: ["web", "b2b", "retail"],
      },
      {
        id: "resources",
        title: "Resources",
        link: "/resources",
        access: ["web", "b2b", "retail", "school"],
      },
      {
        id: "blog",
        title: "Blog",
        link: "/blogs",
        access: ["web", "b2b", "retail", "school"],
      },
      {
        id: "locations",
        title: "Find a Glymed Pro",
        link: "/locations",
        access: ["retail", "school"],
      },
      {
        id: "becomepro",
        title: "Become a Glymed Pro",
        link: "/become-pro",
        isExtendedMenu: false,
        access: ["retail"],
      },
      {
        id: "regen-retail",
        title: "REGEN Retail",
        link: "/regen-resources",
        access: ["b2b"],
      },
    ],
    //indicates that these items are not submenu items, but additional main menu items under 'more'
    //behavior may be different for these than submenus.
    isExtendedMenu: true,
  },
];

//gets a copy of the menu and modifies the options based on the user properties
const getProProfileMenu = (user) => {
  //remove my-store section from menu if user doesn't have a retail store.
  let profileMenu = !user?.hasRetailStore
    ? proProfileMenu.filter((item) => item.id !== "my-store")
    : [...proProfileMenu];

  //remove regen-rewards from menu if user doesn't have 'Professional' discount model.
  const eligibleForRewards = hideRewardPage();
  if (!eligibleForRewards) {
    profileMenu = profileMenu.map((item) => {
      const currentItem = { ...item };
      if (currentItem.id === "my-account") {
        currentItem.menuItems = currentItem.menuItems.filter(
          (subItem) => subItem.id !== "regen-rewards"
        );
      }
      return currentItem;
    });
  }
  return profileMenu;
};

//gets a copy of the menu and modifies the options based on the user properties
const getRetailProfileMenu = () => {
  return [...retailProfileMenu];
};

const getProfileMenu = (user) => {
  let accountType = "retail";
  if (user?.accountType === "pro") accountType = "pro";
  else if (
    user?.accountDivision === "30" &&
    (user?.role === "student" || user?.role === "staff")
  )
    accountType = "school";
  else if (user?.accountDivision === "50") accountType = "retail";

  switch (accountType) {
    case "pro":
      return { title: "REGEN Pro Member", menu: getProProfileMenu(user) };
    case "school":
      return {
        title:
          user?.role === "staff"
            ? "REGEN School Staff"
            : "REGEN School Student",
        menu: schoolProfileMenu,
      };
    case "retail":
    default:
      return {
        title: "REGEN Retail Partner",
        menu: getRetailProfileMenu(user),
      };
  }
};

const authMenu = [
  {
    menuItems: [{ title: "Sign Out", link: "/login" }],
  },
];

const unauthMenu = [
  {
    menuItems: [{ title: "Sign In", link: "/login" }],
  },
  {
    menuItems: [{ title: "Register", link: "/register-retail" }],
  },
];

const cookieNotice = {
  title: "This website uses cookies",
  description:
    "We use cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our Privacy Policy, Terms of Service and our Cookies Usage.",
};

export { navMenu, authMenu, unauthMenu, getProfileMenu, cookieNotice };
